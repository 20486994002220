import React, { Fragment } from "react"

import {Link} from "gatsby";
import Header from "../components/header";
import Menu from "../components/Menu";
import Footer from "../components/footer";

const NotFoundPage = () => (
  <Fragment>

    <>

        <Header />
        <Menu/>

        <div className="section-title pt-5">
            <h1 className="section-title-heading fsm-theme-heading">NOT FOUND</h1>
        </div>
        <div className="text-center p-4">
            <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
            <Link to={'/'}> Go to home </Link>
        </div>

        <Footer />

    </>

  </Fragment>
)

export default NotFoundPage
